:root {
  /* Blue */
  --Blue-500: var(--blue-500);
  --Blue-400: var(--blue-400);
  --Blue-300: var(--blue-300);
  --Blue-200: var(--blue-200);
  --Blue-100: var(--blue-100);

  /* Purple */
  --Purple-500: var(--primary-purple-500);
  --Purple-400: var(--primary-purple-400);
  --Purple-300: var(--primary-purple-300);
  --Purple-200: var(--primary-purple-200);
  --Purple-100: var(--primary-purple-100);

  /* Orange */
  --Orange-500: var(--orange-500);
  --Orange-400: var(--orange-400);
  --Orange-300: var(--orange-300);
  --Orange-200: var(--orange-200);
  --Orange-100: var(--orange-100);

  /* Green */
  --Green-500: var(--green-500);
  --Green-400: var(--green-400);
  --Green-300: var(--green-300);
  --Green-200: var(--green-200);
  --Green-100: var(--green-100);

  /* Pink */
  --Pink-500: var(--pink-500);
  --Pink-400: var(--pink-400);
  --Pink-300: var(--pink-300);
  --Pink-200: var(--pink-200);
  --Pink-100: var(--pink-100);

  /* Grey */
  --Grey-500: var(--gray-500);
  --Grey-400: var(--gray-400);
  --Grey-300: var(--gray-300);
  --Grey-200: var(--gray-200);
  --Grey-100: var(--gray-100);
  --material-teal: #64ffda;
}

.status-sameco {
  background-color: #3372a4;
}

.status-newco {
  background-color: #1bbeaf;
}

.source-flywheel {
  background-color: #c2fcf3;
}

.source-job {
  background-color: #505788;
}

.source-init {
  background-color: #00c9b4;
}
