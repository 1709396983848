/*
  Dark Mode styles for ng-select dropdowns
  ng-select is a 3rd-party dropdown component
 */
.tw-dark {
  .ng-select .ng-select-container,
  .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value,
  .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder,
  .ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,
  .ng-select.ng-select-disabled .ng-clear-wrapper,
  .ng-select .ng-clear-wrapper,
  .ng-select .ng-clear-wrapper:hover,
  .ng-select .ng-clear-wrapper:focus,
  .ng-select .ng-arrow-wrapper .ng-arrow,
  .ng-select .ng-select-container .ng-value-container .ng-placeholder,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: white;
  }

  .ng-select .ng-select-container:after {
    border-bottom: thin solid white;
  }

  .ng-select.ng-select-multiple.ng-select-disabled
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: white;
    background-color: white;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value.ng-value-disabled {
    color: white;
    background-color: white;
  }

  .ng-select .ng-select-container:after {
    border-bottom: thin solid white;
  }

  .ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
  .ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after {
    border: dotted 1px white;
  }

  .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: var(--primary-purple-500);
  }

  .ng-dropdown-panel {
    background: var(--gray-800);
  }

  .ng-dropdown-panel.multiple .ng-option.selected,
  .ng-dropdown-panel.multiple .ng-option.marked {
    background: white;
  }

  .ng-dropdown-panel .ng-dropdown-header,
  .ng-dropdown-panel .ng-dropdown-footer {
    border-bottom: 1px solid white;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: rgba(0, 0, 0, 0.04);
    color: white;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: var(--gray-900);
    color: var(--primary-purple-500);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: var(--gray-400);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    color: rgba(0, 0, 0, 0.38);
  }

  .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-top: 1rem;
  }
}
