// LDT customized Angular Material Toasters (Snackbars)
.mat-snack-bar-container {
  margin-top: 70px !important;
  color: white;
  font-weight: 600;

  &.snackbar-error {
    background-color: #dc2626 !important;
    button {
      color: white !important;
      background-color: #991b1b !important;
      margin-left: 10px;
    }
  }

  &.snackbar-success {
    background-color: #16a34a !important;
    button {
      color: white !important;
      background-color: #166534 !important;
      margin-left: 10px;
    }
  }

  &.snackbar-info {
    background-color: #2563eb !important;
    button {
      color: white !important;
      background-color: #1e40af !important;
      margin-left: 10px;
    }
  }

  &.snackbar-warning {
    background-color: #fb923c !important;
    color: black !important;
    button {
      color: black !important;
      background-color: #ea580c !important;
      margin-left: 10px;
    }
  }
}
