$purple: #231640;
$blue: #4ea8dd;
$light-blue: darken(#f0f8ff, 0.5%);
$crimson: #db3962;
$background: #f2f2f8;
$borders: darken($background, 6%);
$thin-border: rgba(88, 62, 100, 0.3);
$text-color: #222222;
$white: #ffffff;
$black: #333333;
$green: #6ebd7f;
$brown: #9f3223;
$header-h: 70px;
$error: #ff0000;
$success: #40a800;
$input-background: #c0e4fe;
$button-blue: #0082d5;
$placeholder-text: #bcb7b7;
$font: 'Inter', 'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji', sans-serif;

$sidenav-font-color: #7c7c7c;
$selected-color: #6b78c8;
$filters-tree-grey: #e0e0e0;

// Core Colors
$purple-mat: #3f51b5;
$primary: #fafafa;
$secondary: #9e9e9e;
$positive: #7effb2;
$negative: #ff7e7e;
$dark: #2f2f2f;
$darkBackground: #1e1e1e;
$greyBorder: #888888;
$buttonBorder: #888888;

// some tailwind classes
$tw-bg-gray-50: #f9fafb;

// Marketing Site Colors - consider organizing these with prefixes
$marketing-primary-seasalt: #fafafa;
$marketing-primary-cadet-gray: #9e9e9e;
$marketing-secondary-seasalt-10: #fafafa1a;
$marketing-secondary-seasalt-15: #fafafa26;
$marketing-primary-light-green: #7effb2;
$marketing-primary-light-red: #ff7e7e;
$marketing-primary-jet-gray: #2f2f2f;
$marketing-secondary-black-5: #0000000d;
$marketing-primary-eerie-black: #191919;
$marketing-primary-night-black: #0d0d0d;
$marketing-secondary-seasalt-hover: #bdbdbd;
$marketing-primary-davy-gray: #4d4d4d;
$marketing-secondary-black-80: rgba(0, 0, 0, 0.8);
$marketing-secondary-jet-gray-900: #3d3d3d;
$marketing-primary-purple: #5467ca;
$marketing-secondary-seasalt-5: #fafafa0d;
$marketing-secondary-black-60: #00000099;
