/* Styles for the CUSTOMIZED Angular Material dialog container (used in Integrations, etc) */
/* <div class="cdk-overlay-pane ldt-custom-modal"> */
/* base styles for larger screens */
.ldt-custom-modal {
  max-width: 100vw !important;
}
.ldt-custom-modal mat-dialog-container {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* prevents outer scroll */

  height: auto; /* let it grow based on content */
  width: 800px;
  min-height: 800px;
  max-height: 90vh;
}

.ldt-custom-modal mat-dialog-container .ldt-modal-component {
  width: 800px;
}

/* Content and actions remain as they are */
.ldt-custom-modal mat-dialog-content {
  flex: 1 1 auto !important; /* content takes available space */
  max-height: unset !important;
  overflow: auto;
  color: var(--gray-800) !important;
}

.ldt-custom-modal mat-dialog-actions {
  flex-shrink: 0 !important; /* prevents actions from growing */
  margin-top: auto; /* pushes the footer to the bottom */
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Responsive styles for smaller devices: for iPads and smaller */
@media (max-width: 1024px) {
  .ldt-custom-modal mat-dialog-container {
    width: 700px;
    min-height: 600px;
    max-width: 90vw;
    max-height: 80vh;
    padding: 16px;
  }

  .ldt-custom-modal mat-dialog-container .ldt-modal-component {
    width: 700px;
  }
}

/* Responsive styles for smaller devices: for mobile */
@media (max-width: 768px) {
  .ldt-custom-modal .mdc-dialog__title {
    padding-top: 1rem;
  }
  .ldt-custom-modal .mdc-dialog__title::before {
    display: none;
  }

  .ldt-custom-modal mat-dialog-container {
    width: 100vw;
    min-height: auto; /* let height adjust naturally */
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 4px;
  }

  .ldt-custom-modal mat-dialog-container .ldt-modal-component {
    width: 100vw;
    height: 100vh;
    max-height: 1000px;
  }

  .ldt-custom-modal .dialog-header {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .ldt-custom-modal mat-dialog-content {
    overflow-y: auto; /* let scrolling on smaller screens */
    padding: 16px !important; /* override wide padding */
  }

  .ldt-custom-modal mat-dialog-actions {
    padding: 8px; /* Reduce padding for smaller screens */
  }
}

/* for devices wider than 768px but short height (e.g., when users made laptop browser window smaller */
@media (min-width: 768px) and (max-height: 800px) {
  .ldt-custom-modal mat-dialog-container,
  .ldt-custom-modal mat-dialog-container .ldt-modal-component {
    height: 100vh; /* avoid cut-off */
    max-height: unset; /* allow it to grow */
  }

  .ldt-custom-modal .dialog-header {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
}

// DARK MODE
.tw-dark {
  .ldt-custom-modal mat-dialog-content {
    color: var(--gray-100) !important;
  }
}
