.tw-dark {
  // Highcharts timeline labels (Warehouse > Inspect > Signals chart)
  #timeline-signals {
    .highcharts-label-box.highcharts-data-label-box {
      fill: var(--gray-700);
    }
    .highcharts-data-label > span {
      color: white !important;
    }
  }

  // Highcharts Styles: exporting menu popup container
  ul.highcharts-menu {
    background: var(--gray-700) !important;
    border: 1px solid var(--dynamic-chart-grid-lines) !important;
    box-shadow: none !important;

    li.highcharts-menu-item {
      color: white !important;
      padding: 6px 10px !important;
      font-size: 12px;
      transition:
        background 0.3s,
        color 0.3s;
      &:hover {
        background: var(--gray-800) !important;
      }
    }
  }
  .highcharts-exporting-group .highcharts-button.highcharts-button-pressed .highcharts-button-box {
    fill: var(--gray-900) !important;
  }

  .highcharts-legend-navigation {
    fill: var(--dark-white-dynamic-text); /* Use your desired color */
  }
  path.highcharts-legend-nav-active {
    fill: var(--dynamic-link-color) !important; // up/down arrows
  }

  // breadcrumbs buttons
  .highcharts-breadcrumbs-button.highcharts-button-pressed > text,
  .highcharts-breadcrumbs-button.highcharts-button-hover > text {
    fill: white !important;
  }
  .highcharts-button-hover > rect {
    fill: var(--gray-900) !important;
  }
}

.highcharts-breadcrumbs-button.highcharts-button-normal > text,
.highcharts-breadcrumbs-button.highcharts-button-hover > text {
  font-weight: 500 !important;
}
