@font-face {
    font-family: 'icomoon';
    src: url('../../assets/icons/icomoon.eot?y2pm3p');
    src: url('../../assets/icons/icomoon.eot?y2pm3p#iefix')
            format('embedded-opentype'),
        url('../../assets/icons/icomoon.ttf?y2pm3p') format('truetype'),
        url('../../assets/icons/icomoon.woff?y2pm3p') format('woff'),
        url('../../assets/icons/icomoon.svg?y2pm3p#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert-triangle:before {
    content: '\e90a';
}
.icon-edit:before {
    content: '\e909';
}
.icon-more-horizontal:before {
    content: '\e906';
}
.icon-arrow-left:before {
    content: '\e907';
}
.icon-menu:before {
    content: '\e900';
}
.icon-upload:before {
    content: '\e901';
}
.icon-download:before {
    content: '\e902';
}
.icon-search:before {
    content: '\e903';
}
.icon-arrow-right:before {
    content: '\e904';
}
.icon-check:before {
    content: '\e908';
}
.icon-triangle-down:before {
    content: '\e905';
}
.icon-file-text2:before {
    content: '\e926';
}
.icon-chrome:before {
    content: '\eae4';
}
