.ag-theme-alpine-dark,
.ag-theme-balham-dark,
.ag-theme-quartz-dark {
  --ag-background-color: var(--gray-800);
  --ag-header-background-color: var(--gray-700);
  --ag-foreground-color: #fff;
  --ag-odd-row-background-color: var(--gray-700);
  --ag-border-color: var(--gray-500);
  --ag-selected-row-background-color: #2a2c8d; // dark deep indigo
  --ag-row-hover-color: #2a2c8d;
  --ag-secondary-border-color: var(--gray-500);
  --ag-header-column-separator-color: var(--gray-400);

  --ag-tooltip-background-color: color-mix(in srgb, #fff, #182230 96%);
  --ag-control-panel-background-color: color-mix(in srgb, #fff, #182230 93%);
  --ag-input-disabled-background-color: #68686e12;
  --ag-card-shadow: 0 1px 20px 1px black;
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-disabled-border-color: rgba(255, 255, 255, 0.07);
  --ag-checkbox-unchecked-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 40%
  );
  --ag-panel-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 10%
  );
  --ag-panel-border-color: color-mix(in srgb, transparent, var(--ag-foreground-color) 10%);
  --ag-menu-background-color: color-mix(
    in srgb,
    var(--ag-background-color),
    var(--ag-foreground-color) 10%
  );
  --ag-menu-border-color: color-mix(in srgb, transparent, var(--ag-foreground-color) 10%);
}

.ag-theme-balham-dark {
  .ag-input-field-input {
    color: white !important;
    font-weight: 600 !important;
  }
}

/* custom Ag-Grid styles */
.ag-theme-balham .ag-input-field-input {
  color: #000 !important; /* Dark text color */
  font-weight: 600 !important; /* Bold text */
}
