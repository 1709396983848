@use '@angular/material' as mat;

/* Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

// Include core styles for MDC components
@include mat.core();

// Include legacy core styles for legacy components
@include mat.legacy-core();

@import 'scss/abstracts/variables.scss';
// custom typography to use Inter font
$custom-typography: mat.define-legacy-typography-config(
  $font-family: $font,
);

/* Light theme defined */
$LiveData-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$indigo-palette),
      warn: mat.define-palette(mat.$red-palette),
    ),
    typography: $custom-typography,
    density: 0,
  )
);

/* Dark theme defined */
$LiveData-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette(mat.$teal-palette, A200, A100, A400),
      accent: mat.define-palette(mat.$teal-palette, A200, A100, A400),
      warn: mat.define-palette(mat.$red-palette, 600),
    ),
  )
);

/* Light theme: all styles for legacy and new */
@include mat.all-legacy-component-themes($LiveData-theme);
@include mat.all-component-themes($LiveData-theme);

/* Dark theme: Apply themes based on the 'tw-dark' class, but only colors */
.tw-dark {
  @include mat.all-legacy-component-colors($LiveData-dark-theme);
  @include mat.all-component-colors($LiveData-dark-theme);
}

@import 'scss/base/normalize/normalize';
@import 'scss/base/icomoon.scss';
@import 'scss/base/fonts/sanfrancisco.scss';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // dark theme is included by default
@import 'ag-grid-community/styles/ag-theme-balham.css'; // dark theme is included by default
@import 'assets/ag-grid-quartz-theme/ag-theme-quartz.min.css'; // very nice ag-grid theme: modern-looking, soft dark colors, more space between rows (AG Grid new default, recommended theme)
@import 'scss/custom-ag-grid.scss';
@import 'scss/custom-highcharts.scss';
@import 'scss/colors.scss';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import 'scss/ng-select-dark-mode.scss'; // MB's custom dark mode styles for ng-select
@import 'scss/snackbars.scss';
@import 'scss/ldt-custom-modal.scss';

// this creates dynamically-colored logo, no need to swap images
.ldt-logo,
.black-inverted-img {
  filter: invert(1); // in light mode, white logo is inverted into black
  transition: filter 0.3s ease;
}
.ldt-logo-white-gray {
  filter: invert(0.8); // in light mode, white logo inverted into gray
}

/* 
* BASIC RESETS
*/
html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  margin: 0;
  background-color: $background;
  @apply tw-bg-gray-50;
  font-family: $font;

  // prevent scrolling of body when subscription drawer is open
  &:has(.subscription-drawer-open) {
    overflow: hidden !important;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-purple-600);
}

button,
input[type='button'] {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  font-family: inherit;
}

input {
  outline: none;
}

.container {
  height: calc(100vh - 12px);
  background-color: var(--gray-100);
  padding: 12px;
  gap: 16px;
  overflow-y: scroll;

  @media (min-width: 1280px) {
    height: calc(100vh - 16px);
  }
}

/* 
* ANGULAR MATERIAL MODIFICATIONS
*/
:root {
  // CSS variables for Light mode
  --filters-tree-grey: var(--gray-300);
  --gray-dynamic-text: var(--gray-600);
  --dark-light-dynamic-text: var(--gray-800);
  --dark-white-dynamic-text: var(--gray-800);
  --black-white-dynamic-text: black;
  --dark-white-dynamic-bg: white;
  --black-white-dynamic-bg: white;
  --dynamic-chart-grid-lines: var(--gray-300);
  --dynamic-chart-legend-hover: var(--gray-900);
  --dynamic-hover-bg: var(--gray-300);
  --dynamic-accent-bg: var(--gray-300);
  --dynamic-link-color: var(--primary-purple-600);
  --dynamic-link-darker-color: var(--primary-purple-600);
  --white-dark-dynamic-text: white;
  --charts-dynamic-color: var(--primary-purple-600);
  --dynamic-link-color-opacity-15: rgba(
    142,
    145,
    255,
    0.15
  ); // for active nav links or tabs bg color
  --dynamic-gray-100-700: var(--gray-100);

  /* Default Tailwind gray scale */
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  .mat-button-toggle {
    color: var(--gray-800);
    background-color: transparent;
    &.mat-button-toggle-checked {
      background-color: var(--gray-300);
      cursor: default;
      font-weight: 600;
      button {
        cursor: default;
      }
      mat-icon.mat-icon {
        height: 22px;
        width: 22px;
        line-height: 20px;
        font-size: 16px;
        padding-right: 3px;
        font-weight: 600;
      }
    }
  }
  .mat-primary
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: var(--gray-800);
  }
  .mat-tab-group.mat-tab-fill-height .mat-tab-body-wrapper {
    flex: 1 1 100vh;
  }

  .panel-vertical-tabs
    .mat-tab-header
    .mat-tab-label-container
    .mat-tab-list
    .mat-tab-label.mat-tab-label-active {
    background-color: var(--gray-300) !important;
  }

  .active-tab-nav {
    border-bottom: var(--primary-purple-600);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    color: var(--primary-purple-600);
    opacity: 1 !important;
  }

  .mat-tab-nav-bar {
    border: 0;
  }

  .mat-tab-header {
    border: 0;
  }

  // global tooltip styles for Angular Material tooltips
  .mat-tooltip {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(71, 71, 71) !important;
    border: 1px solid rgb(85, 85, 85);
    padding: 7px 10px;
    font-size: 12px;
    font-weight: 500;
    box-shadow:
      0 2px 4px rgba(0, 0, 0, 0.1),
      0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }

  // custom styles for the info tooltip (only affects tooltips w/ that class)
  // is needed b/c angular material doesn't allow html inside tooltips
  .multiline-tooltip {
    white-space: pre-line !important; // if the content is multiline, it will break lines
    width: 340px;
    max-width: none !important;
    line-height: 1.3rem;
    font-size: 12.5px !important;
  }

  .mat-tab-link:not(.active-tab-nav) .beta-badge {
    color: var(--dark-light-dynamic-text) !important;
  }

  .mat-drawer-container {
    .mat-drawer-content {
      background-color: var(--gray-100);
    }
  }
}

/* 
* DARK MODE styles
*/
.tw-dark {
  // CSS variables (dynamic)
  --filters-tree-grey: var(--gray-400);
  --gray-dynamic-text: var(--gray-300);
  --dark-light-dynamic-text: var(--gray-200);
  --dark-white-dynamic-text: white;
  --black-white-dynamic-text: white;
  --dark-white-dynamic-bg: var(--gray-800);
  --black-white-dynamic-bg: var(--gray-900);
  --dynamic-accent-bg: var(--gray-700);
  --dynamic-chart-grid-lines: var(--gray-500);
  --dynamic-chart-legend-hover: var(--gray-300);
  --dynamic-hover-bg: var(--gray-900);
  --dynamic-link-color: var(--accent-teal-A200);
  --dynamic-link-darker-color: var(--teal-500);
  --white-dark-dynamic-text: var(--gray-800);
  --charts-dynamic-color: var(--primary-purple-500);
  --dynamic-link-color-opacity-15: rgba(100, 255, 218, 0.15);
  --dynamic-gray-100-700: var(--gray-700);

  // Adjust Tailwind gray scale to remove blue hues
  --gray-100: #f5f5f5;
  --gray-200: #e5e5e5;
  --gray-300: #d4d4d4;
  --gray-400: #a3a3a3;
  --gray-500: #737373;
  --gray-600: #525252;
  --gray-700: #2c2c2c;
  --gray-800: #1c1c1c;
  --gray-900: #000000;

  .ldt-logo,
  .ldt-logo-white-gray,
  .black-inverted-img {
    filter: invert(0); // in dark mode, logo is normal white
    transition: filter 0.3s ease;
  }

  .container {
    background-color: var(--gray-900) !important;
    color: white;
  }
  a {
    // color: var(--primary-purple-500);
    color: var(--accent-teal-A200);
  }
  .mat-card {
    background-color: var(--gray-800) !important;
    color: var(--gray-100);
  }
  .mat-button {
    color: var(--gray-100);
  }
  .mat-stroked-button:not(.mat-button-disabled),
  .mat-stroked-button:not(.gradient-stroked-btns) {
    border-color: var(--gray-600) !important;
    color: var(--accent-teal-A200) !important;
  }
  .mat-stroked-button.gradient-stroked-btns:not(.mat-button-disabled) {
    background-color: transparent !important;
    border-color: var(--gray-200) !important;
  }
  .mat-raised-button:not(.mat-button-disabled, .mat-warn) {
    background-color: transparent;
    color: var(--accent-teal-A200) !important;
    border: 1px solid var(--teal-600);
    &:hover {
      background-color: var(--gray-700);
    }
  }
  .mat-raised-button.mat-button-disabled,
  .mat-raised-button.mat-primary.mat-button-disabled {
    color: var(--gray-400) !important;
  }
  .mat-raised-button.mat-primary {
    background-color: var(--accent-teal-A200);
    color: var(--gray-800) !important;
    &:hover {
      background-color: var(--teal-400);
    }
  }
  .mat-stroked-button.mat-primary {
    color: var(--primary-purple-500);
  }
  .mat-stroked-button {
    color: var(--gray-100);
  }
  .mat-dialog-container {
    background-color: var(--gray-800) !important;
    color: white;
  }
  .mat-mdc-dialog-surface,
  .mat-dialog-container {
    border: 1px solid var(--gray-600);
    background-color: var(--gray-800) !important;
  }
  .mat-autocomplete-panel {
    background-color: var(--gray-800) !important;
    border: 1px solid var(--gray-500);
    color: white;
  }
  .mat-button-toggle-group {
    border: 1px solid var(--gray-500) !important;
  }
  .mat-button-toggle-appearance-standard {
    background-color: var(--gray-600);
  }
  .mat-expansion-panel-header {
    background-color: var(--gray-700) !important;
  }
  .mat-expansion-panel-content {
    background-color: var(--gray-800) !important;
  }
  .mat-button-toggle {
    color: white;
    background-color: transparent;
    &.mat-button-toggle-checked {
      // color: var(--accent-teal-A200);
      background-color: var(--gray-700);
      cursor: default;
      button {
        cursor: default;
      }
    }
  }

  .mat-mdc-select-panel,
  .mat-menu-panel,
  .mat-select-panel,
  .mat-calendar {
    background-color: var(--gray-700) !important;
  }
  .code-font-direct {
    background: var(--gray-700);
    border: 1px solid var(--gray-600);
    color: white;
  }
  .mat-table {
    background-color: var(--gray-800) !important;
  }
  .active-tab-nav {
    border-bottom: var(--accent-teal-A200);
    color: var(--accent-teal-A200);
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  .mat-primary
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: white;
  }
  .panel-vertical-tabs
    .mat-tab-header
    .mat-tab-label-container
    .mat-tab-list
    .mat-tab-label.mat-tab-label-active {
    background-color: var(--gray-600) !important;
  }

  // Custom scrollbar styles for DM
  /* Webkit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #303030; /* Dark background */
    background-color: var(--gray-600) !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #555; /* Darker thumb */
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #777; /* Slightly lighter on hover */
  }
  /* Firefox */
  scrollbar-color: #555 #303030; /* thumb color | track color */
  scrollbar-width: thin; /* Can be auto, thin, or none */
  // end custom scrollbar styles for DM

  .mat-drawer-container {
    .mat-drawer-backdrop {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }
    .mat-drawer-content {
      background-color: var(--gray-900);
    }
  }
}
// End of DM styles

// Material Dialogs (not legacy) - full width on mobile
// this should probably be a global style (b/c it makes sense for all dialogs), but for now...
// this custom class is set in the dialog config in the component
.full-w-mobile-dialog {
  /* Responsive styles for small screens */
  @media (max-width: 500px) {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;

    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }

    .mat-mdc-dialog-container {
      padding: 0 !important;
    }

    .mat-mdc-dialog-content {
      max-height: none !important;
      height: auto !important;
    }
  }

  @media (min-width: 500px) {
    min-width: 500px;
  }
}

// Material Dialogs (not legacy) - full width on mobile
// this should probably be a global style (b/c it makes sense for all dialogs), but for now...
// this custom class is set in the dialog config in the component
.full-w-mobile-dialog {
  /* Responsive styles for small screens */
  @media (max-width: 500px) {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;

    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }

    .mat-mdc-dialog-container {
      padding: 0 !important;
    }

    .mat-mdc-dialog-content {
      max-height: none !important;
      height: auto !important;
    }
  }

  @media (min-width: 500px) {
    min-width: 500px;
  }
}

/* 
* MISCELLANEOUS STYLES
*/
.content-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.content-description {
  font-size: 14px;
  font-weight: 100;
  margin: 10px 0px;
}

.app-settings-billing-container {
  mat-slider {
    .mat-slider-thumb {
      width: 50px;
      height: 50px;
    }

    .mat-slider-thumb::after {
      content: 'TOOOO';
    }
  }

  .stepper-container {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    ngx-slider {
      width: 70%;

      .ngx-slider-inner-tooltip {
        font-size: large;
      }
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: var(--primary-purple-600);
  animation: spinner 0.8s linear infinite;
}

.tw-dark .spinner:after {
  border: 2px solid var(--accent-teal-A200);
  border-top-color: var(--gray-800);
}

.badge-checked-items {
  background-color: $purple-mat;
  padding: 3px 7px;
  border-radius: 25%;
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.badge-selected-items {
  border-radius: 2px;
  background-color: $purple-mat;
  padding: 3px 7px;
  color: white;
  font-size: 12px;
  font-weight: 300;
  height: 22px;
}

.close-inside-badge {
  cursor: pointer;
  color: white;
  height: 16px !important;
  width: 16px !important;
  font-size: 16px;
  margin-left: 3px;
}

// custom icon button that becomes visible on hover
.icon-btn-hover {
  border: 1px solid white;
  border-radius: 3px;
  padding-top: 4px;
  align-self: center;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background: #eaeaea;
    border: 1px solid grey;
  }
}

// small delete (x) icon button w/ hover & focus effects
.delete-icon-btn {
  width: 22px;
  height: 22px;
  margin-left: 2px;

  border: 1px solid transparent;
  border-radius: 3px;
  padding-top: 4px;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid;
  }

  .delete-icon {
    top: 2px;
    left: -2px;
    font-size: 18px;
    line-height: 16px;
  }
}

.badge-sm {
  padding: 2px 5px;
  border-radius: 3px;
  color: white;
  background-color: #6e6e6e;
  font-size: 11px;
  // font-weight: 300;
}
.badge-sm:not(:last-child) {
  margin-right: 4px;
}

.code-font-direct {
  font-family: 'Source Code Pro', sans-serif !important;
  background: #f2f2f2;
  padding: 2px 6px;
  border-radius: 2px;
  line-height: 27px;
  border: 1px solid lightgrey;
  font-size: 13px;
  line-height: 17px;
}

.font-code-pro {
  font-family: 'Source Code Pro', sans-serif !important;
}

.badge-md-light {
  background: #f2f2f2;
  padding: 2px 6px;
  border-radius: 2px;
  line-height: 27px;
  border: 1px solid lightgrey;
  font-size: 13px;
  line-height: 17px;
}

.tw-font-13 {
  font-size: 0.81rem !important;
}

.info-icon {
  font-size: 14px;
  line-height: 16px;
  height: 14px;
  width: 14px;
}

.custom-shadow {
  box-shadow:
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.mat-card-shadow {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.compact-btn {
  line-height: 14px !important;
  font-size: 14px !important;
  padding-top: 3px !important;
  height: 1.75rem !important;
}

.ngx-json-viewer .segment .segment-main .segment-key {
  color: var(--dynamic-link-color) !important;
}

// custom styles for Flatfile modal only when sidebar is open; otherwise it takes full width
#main-sidenav {
  &:has(.mat-sidenav.mat-drawer-opened) {
    .mat-sidenav-content {
      .flatfile_iframe-wrapper {
        margin-left: 200px;
        width: calc(100% - 300px) !important;
      }
    }
  }
}

#people-nav-btn .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flatfile-close-button {
  right: 10.5px !important;
  top: 10.5px !important;
}
